<template>
    <List
      :title="title"
      :data="data"
      :noDataText="noDataText"
      :hasNext="hasNext"
      :hasPrev="hasPrev"
      :displayAll="true" 
      @fetchList="getAccounts"
  />
</template>

<script>
import { mapActions } from "vuex";
import List from "../../components/organization/accountManagersList.vue";

export default {
  components: {
    List
  },
  data() {
    return {
      data: [],
      hasNext: false,
      hasPrev: false,
      noDataText: 'Loading...',
      title: "Account Managers"
    };
  },
  methods: {
      ...mapActions("appUser", ["fetchAccountManagers"]),

    async getAccounts(params) {
      let self = this;
      this.data = [];
      await this.fetchAccountManagers(params).then(res => {
        self.data = res.data.data.docs;
        self.hasNext = res.data.data.hasNext;
        self.hasPrev = res.data.data.hasPrev;
        self.noDataText = "No Logs Available";
      });
    }
  }
}
</script>